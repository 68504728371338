@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 221.2 83.2% 53.3%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 44%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 72% 51%;
        --destructive-foreground: 210 40% 98%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 221.2 83.2% 53.3%;
        --chart-1: 221.2 83.2% 53.3%;
        --chart-2: 212 95% 68%;
        --chart-3: 216 92% 60%;
        --chart-4: 210 98% 78%;
        --chart-5: 212 97% 87%;
        --radius: 0.5rem;
    }

    .dark {
        --background: 240 10% 3.9%;
        --foreground: 0 0% 98%;
        --card: 240 10% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 240 10% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 221.2 83.2% 53.3%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 240 3.7% 15.9%;
        --muted-foreground: 240 5% 64.9%;
        --accent: 240 3.7% 15.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 72% 51%;
        --destructive-foreground: 210 40% 98%;
        --border: 240 3.7% 15.9%;
        --input: 240 3.7% 15.9%;
        --ring: 221.2 83.2% 53.3%;
        --chart-1: 221.2 83.2% 53.3%;
        --chart-2: 212 95% 68%;
        --chart-3: 216 92% 60%;
        --chart-4: 210 98% 78%;
        --chart-5: 212 97% 87%;
        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

.d-none {
    display: none;
}

.landing-hero-badge {
    @apply inline-flex items-center gap-x-1.5 px-3 py-0.5 text-sm font-medium rounded-full border border-orange-500 bg-orange-200/85 text-orange-600 hover:bg-orange-200 transition-opacity duration-200;
}

.landing-hero-badge span {
    @apply underline;
}

.cta_section:before {
    @apply bg-zinc-50;
    position: absolute;
    inset: 0;
    transform: matrix(1, 0, 0, 1, 0, 221.031);
    border-radius: 1.5rem;
    content: "";
    z-index: -1;
}

.star-ratings {
    position: relative;
    display: block;
    width: 70px;
    height: 16px;
}

.star-ratings .fill-ratings {
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI5Ljg5NSAxMi41MmExLjk0MyAxLjk0MyAwIDAgMC0xLjU0OS0xLjMxOWwtNy4zMDktMS4wOTUtMy4yOS02Ljk4NEMxNy40MiAyLjQzIDE2Ljc1MSAyIDE2IDJzLTEuNDIuNDMtMS43NDcgMS4xMjJsLTMuMjQyIDYuOTU5LTcuMzU3IDEuMTJjLS43Mi4xMS0xLjMxMy42MTUtMS41NDkgMS4zMTlhMi4wMDQgMi4wMDQgMCAwIDAgLjQ2NSAyLjA0Nmw1LjMyMSA1LjQ0Ni0xLjI1NyA3LjY3NmExLjk4NCAxLjk4NCAwIDAgMCAuODExIDEuOTU5IDEuODkgMS44OSAwIDAgMCAyLjAyLjExNGw2LjQ4OS0zLjYyNCA2LjU4MSAzLjYyNGExLjg5MyAxLjg5MyAwIDAgMCAyLjAyLS4xMTQgMS45ODIgMS45ODIgMCAwIDAgLjgxMS0xLjk1OWwtMS4yNTktNy42ODYgNS4zMjMtNS40MzZhMi4wMDQgMi4wMDQgMCAwIDAgLjQ2NS0yLjA0NnoiIGZpbGw9IiNmMWM0MGYiIGNsYXNzPSJmaWxsLTAwMDAwMCBmaWxsLWYwZTE2YyI+PC9wYXRoPjwvc3ZnPg==);
    background-size: 14px 14px;
    height: 14px;
}

.star-ratings .empty-ratings {
    padding: 0;
    display: block;
    z-index: 0;
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI5Ljg5NSAxMi41MmExLjk0MyAxLjk0MyAwIDAgMC0xLjU0OS0xLjMxOWwtNy4zMDktMS4wOTUtMy4yOS02Ljk4NEMxNy40MiAyLjQzIDE2Ljc1MSAyIDE2IDJzLTEuNDIuNDMtMS43NDcgMS4xMjJsLTMuMjQyIDYuOTU5LTcuMzU3IDEuMTJjLS43Mi4xMS0xLjMxMy42MTUtMS41NDkgMS4zMTlhMi4wMDQgMi4wMDQgMCAwIDAgLjQ2NSAyLjA0Nmw1LjMyMSA1LjQ0Ni0xLjI1NyA3LjY3NmExLjk4NCAxLjk4NCAwIDAgMCAuODExIDEuOTU5IDEuODkgMS44OSAwIDAgMCAyLjAyLjExNGw2LjQ4OS0zLjYyNCA2LjU4MSAzLjYyNGExLjg5MyAxLjg5MyAwIDAgMCAyLjAyLS4xMTQgMS45ODIgMS45ODIgMCAwIDAgLjgxMS0xLjk1OWwtMS4yNTktNy42ODYgNS4zMjMtNS40MzZhMi4wMDQgMi4wMDQgMCAwIDAgLjQ2NS0yLjA0NnoiIGZpbGw9IiNjY2NjY2MiIGNsYXNzPSJmaWxsLTAwMDAwMCBmaWxsLWUwZTBlMCI+PC9wYXRoPjwvc3ZnPg==);
    width: 100%;
    height: 14px;
}